import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Fab from "@mui/material/Fab";
import { teal } from "@mui/material/colors";
import NavigationIcon from "@mui/icons-material/Navigation";

const ligne1 = [
  {
    title: "Séance Individuelle (*)",
    subheader: "(1h)",
    price: "65",
    description: [],
    buttonText: "Contactez moi",
    buttonVariant: "contained",
  },
  {
    title: "Séance de groupe",
    subheader: "(1h 3 à 5 personnes) / par personne",
    price: "15",
    description: [],
    buttonText: "Contactez moi",
    buttonVariant: "contained",
  },
];
const tiers = [
  {
    title: "Pack 5 séances",
    subheader: "Individuelles",
    price: "270",
    description: [],
    buttonText: "Contactez moi",
    buttonVariant: "contained",
  },
  {
    title: "Pack 5 séances",
    subheader: "Etudiant",
    price: "250",
    description: [],
    buttonText: "Contactez moi",
    buttonVariant: "contained",
  },
  {
    title: "Ateliers à Thème",
    subheader: "(3h 10 personnes maxi)",
    price: "450",
    description: [],
    buttonText: "Contactez moi",
    buttonVariant: "contained",
  },
];

function Tarifs() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "fixed", bottom: 32, right: 16, zIndex: 1050 }}
          onClick={(e) => goToTop(e)}
        >
          <NavigationIcon />
        </Fab>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Tarifs et devis
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {ligne1.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={6}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: teal[100],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      {tier.price}€
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    href="mailto:contact@cmalter.com?subject=demande de renseignements"
                    color="primary"
                    variant={tier.buttonVariant}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <br />
      <br />
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: teal[100],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      {tier.price}€
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    href="mailto:contact@cmalter.com?subject=demande de renseignements"
                    color="primary"
                    variant={tier.buttonVariant}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <br />
      <br />
      <Grid container spacing={0} sx={{ display: { xs: "flex", md: "flex" } }}>
        <Grid item xs={1} md={1}></Grid>
        <Grid item xs={10} md={10}>
          <div className="App">
            <Typography
              variant="h6"
              align="left"
              color="text.secondary"
              component="p"
            >
              *possibilité de séance à domicile sur un périmètre autour de mon
              cabinet, à convenir ensemble.
              <br></br>
              <br></br>
              Les séances ne sont pas prises en charge par la Sécurité Sociale
              mais peuvent faire l’objet d’un remboursement par la mutuelle, en
              fonction du contrat, pour médecine douce.{" "}
              <Link href="mailto:contact@cmalter.com?subject=demande de devis">
                contactez moi
              </Link>
              .<br></br>
              <br></br>
              <b>Conditions Générales de Vente</b>
              <br></br>
              En prenant rendez-vous, le client accepte les conditions générales
              de ventes modalités de règlement : espèces, chèque ou virement, en
              fin de séance ou à réception de la facture.
              <br></br> <br></br>
              <b>Modalités d’annulation</b>
              <br></br>Toute séance non annulée 48h à l’avance sera due sauf cas
              de force majeure.
              <br></br>
              <br></br>
              <b>Traitement des réclamations et litiges :</b>
              <br></br>
              Catherine Malter a signé une convention avec la Chambre Nationale
              des Praticiens de la Médiation 27 avenue de la Libération 42400
              Saint-Chamond
              <br></br> (téléphone 09.88.30.27.72 &nbsp;
              <Link href="mailto:contact-admin@cnpm-mediation-consommation.eu">
                contact-admin@cnpm-mediation-consommation.eu
              </Link>
              )<br></br>
              adhérente à la Chambre syndicale de la Sophrologie &nbsp;
              <Link href="https://www.chambre-syndicale-sophrologie.fr/reglement-usage-des-marques/">
                https://www.chambre-syndicale-sophrologie.fr/reglement-usage-des-marques/
              </Link>
              <br></br>
              <br></br>
              <b>Me contacter</b>
              <br></br>
              par téléphone : 06.82.74.60.20
              <br></br>
              par mail : &nbsp;
              <Link href="mailto:contact@cmalter.com">contact@cmalter.com</Link>
              <br></br>
              Cabinet sur rendez-vous : 2 rue de Saint Leu 94520 Perigny sur
              Yerres
            </Typography>
          </div>
        </Grid>
        <Grid item xs={1} md={1}></Grid>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </React.Fragment>
  );
}

export default Tarifs;
