import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MailIcon from "@mui/icons-material/Mail";
import Drawer from "@mui/material/Drawer";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
//import InstagramIcon from "@mui/icons-material/Instagram";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
//import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import InboxIcon from "@mui/icons-material/MoveToInbox";

import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import StickyFooter from "./StickyFooter";

function Layout() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: 230 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key="Accueil" component="a" href="/">
          <ListItemText primary="Accueil" />
        </ListItem>
        <ListItem button key="Qui suis-je ?" component="a" href="/quisuisje">
          <ListItemText primary="Qui suis-je ?" />
        </ListItem>
        <ListItem button key="Offre" component="a" href="/tarifs">
          <ListItemText primary="Offre/Tarifs" />
        </ListItem>
        <ListItem
          button
          key="Contact et rendez-vous"
          component="a"
          href="/contact"
        >
          <ListItemText primary="Contact et rendez-vous" />
        </ListItem>
        <Divider />
        <IconButton
          color="inherit"
          href="mailto:contact@cmalter.com?subject=demande de renseignements"
          sx={{ ml: 1 }}
        >
          <MailIcon />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://www.facebook.com/profile.php?id=100080281640537"
          target="_blank"
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://www.instagram.com/cmaltercoaching/"
          target="_blank"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://www.linkedin.com/company/catherine-malter-coaching"
          target="_blank"
        >
          <LinkedInIcon />
        </IconButton>
      </List>
    </Box>
  );
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
      <AppBar
        position="relative"
        sx={{
          backgroundColor: { xs: "#333333", md: "#333333" },
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box
            sx={{
              height: { xs: "60px", md: "70px" },
            }}
          >
            <a href="/">
              <img
                sx={{ display: { xs: "none", md: "flex" } }}
                src="images/logoCMalterdotcom-01-GRIS-SIMPLE-real.svg"
                alt="cmcoaching"
                height="100%"
              />
            </a>
          </Box>
          <Typography variant="h6" color="inherit" noWrap>
            &nbsp;
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              flexDirection: "row-reverse",
            }}
          >
            <nav sx={{}}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", lg: "flex" },
                  flexDirection: "row-reverse",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 0, mb: -0.7, display: { xs: "block", md: "none" } }}
                  onClick={toggleDrawer("right", true)}
                >
                  <MenuIcon />
                </IconButton>
                <Button
                  onClick={toggleDrawer("right", true)}
                  sx={{
                    my: 2,
                    color: "white",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Menu
                </Button>
                <IconButton
                  color="inherit"
                  href="https://www.linkedin.com/company/catherine-malter-coaching"
                  target="_blank"
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.instagram.com/cmaltercoaching/"
                  target="_blank"
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.facebook.com/profile.php?id=100080281640537"
                  target="_blank"
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="mailto:contact@cmalter.com?subject=demande de renseignements"
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <MailIcon />
                </IconButton>
                <Button
                  href="/contact"
                  sx={{
                    my: 2,
                    color: "white",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Contact et rendez-vous
                </Button>
                <Button
                  href="/tarifs"
                  sx={{
                    my: 2,
                    color: "white",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Offre/Tarifs
                </Button>
                <Button
                  href="/quisuisje"
                  sx={{
                    my: 2,
                    color: "white",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Qui suis-je ?
                </Button>
                <Button
                  href="/"
                  sx={{
                    my: 2,
                    color: "white",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Accueil
                </Button>
              </Box>
            </nav>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>

      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />
      <StickyFooter />
    </div>
  );
}

export default Layout;
