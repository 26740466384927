import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Container, Typography, Grid, Box, Fab } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import { InlineWidget } from "react-calendly";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
//import { grey, deepOrange } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import MailIcon from "@mui/icons-material/Mail";
import NavigationIcon from "@mui/icons-material/Navigation";
import "./contact.css";

function Contact() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 2 }}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "fixed", bottom: 32, right: 16, zIndex: 1050 }}
          onClick={(e) => goToTop(e)}
        >
          <NavigationIcon />
        </Fab>
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Container disableGutters component="main" sx={{ pt: 8, pb: 6 }}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Contact et rendez-vous
            </Typography>
          </Container>
          <Grid
            container
            spacing={0}
            sx={{ display: { xs: "flex", md: "flex" } }}
          >
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={10} md={10}>
              <div className="App">
                <Typography
                  component="h1"
                  variant="h5"
                  align="left"
                  color="text.primary"
                  gutterBottom
                >
                  Prendre un rendez-vous :
                </Typography>

                <InlineWidget
                  url="https://calendly.com/catherine-malter-coaching/"
                  iframeTitle="Prise de contact"
                  pageSettings={{
                    backgroundColor: "ffffff",
                    hideEventTypeDetails: false,
                    hideGdprBanner: true,
                    hideLandingPageDetails: false,
                    primaryColor: "00a2ff",
                    textColor: "4d5055",
                  }}
                  styles={{
                    height: "750px",
                  }}
                />
                <Typography
                  component="h1"
                  variant="h5"
                  align="left"
                  color="text.primary"
                  gutterBottom
                >
                  Me contacter :
                </Typography>
                <Typography
                  component="body"
                  variant="body"
                  align="left"
                  color="text.primary"
                  gutterBottom
                >
                  <p>
                    Par mail{" "}
                    <IconButton
                      color="inherit"
                      href="mailto:contact@cmalter.com?subject=demande de renseignements"
                    >
                      <MailIcon />
                    </IconButton>
                  </p>
                  <p>
                    Par téléphone &#10132; merci de prendre un rendez-vous "1er
                    contact - 30 mn gratuit".
                  </p>
                  <br />
                </Typography>
              </div>
              <br />
              <Typography
                component="h1"
                variant="h5"
                align="left"
                color="text.primary"
                gutterBottom
              >
                Me situer :
              </Typography>

              <Typography
                component="body"
                variant="body"
                align="left"
                color="text.primary"
                gutterBottom
              >
                <p>
                  Catherine Malter - 2 rue de saint Leu - 94520 Périgny sur
                  Yerres
                </p>
              </Typography>
              <br />
              <MapContainer
                center={[48.69581, 2.55284]}
                zoom={14}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[48.69581, 2.55284]}>
                  <Popup>
                    CMalter
                    <br />2 rue de Saint Leu
                    <br />
                    94520 Périgny sur Yerres
                  </Popup>
                </Marker>
              </MapContainer>
              <br />
              <br />
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
        </Box>
      </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
}

export default Contact;
