import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { teal } from "@mui/material/colors";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://cmalter.com/">
        CMalter.com
      </Link>{" "}
      2022, tous droits réservés.
      <br />
      <Link color="inherit" href="/">
        Accueil
      </Link>
      &nbsp;&nbsp;
      <Link color="inherit" href="/quisuisje">
        Qui suis-je ?
      </Link>
      &nbsp;&nbsp;
      <Link color="inherit" href="/tarifs">
        Offre/Tarifs
      </Link>
      &nbsp;&nbsp;
      <Link color="inherit" href="/contact">
        Contact et rendez-vous
      </Link>
      <IconButton
        color="inherit"
        href="https://www.facebook.com/profile.php?id=100080281640537"
        target="_blank"
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        color="inherit"
        href="https://www.instagram.com/cmaltercoaching/"
        target="_blank"
      >
        <InstagramIcon />
      </IconButton>
      <IconButton
        color="inherit"
        href="https://www.linkedin.com/company/catherine-malter-coaching"
        target="_blank"
      >
        <LinkedInIcon />
      </IconButton>
      <br />
      <Link color="inherit" href="/mentions">
        Identité de l’entreprise
      </Link>
      &nbsp;&nbsp;
      <Link color="inherit" href="/mentions">
        Identité de l’hébergeur
      </Link>
      &nbsp;&nbsp;
      <Link color="inherit" href="/mentions">
        CGV
      </Link>
      &nbsp;&nbsp;
      <Link color="inherit" href="/mentions">
        Politique de confidentialité
      </Link>
      <br />
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "10vh",
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: teal[50],
        }}
      >
        <Container maxWidth="sm">
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
