import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography, Container, Grid, Box, Fab, Link } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import NavigationIcon from "@mui/icons-material/Navigation";

import { teal } from "@mui/material/colors";

function Mentions() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 2 }}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "fixed", bottom: 32, right: 16, zIndex: 1050 }}
          onClick={(e) => goToTop(e)}
        >
          <NavigationIcon />
        </Fab>
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{ pt: 8, pb: 6 }}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Mentions légales
            </Typography>
          </Container>
          <Grid
            container
            spacing={0}
            sx={{ display: { xs: "flex", md: "flex" } }}
          >
            <Grid item xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid item xs={10} md={10}>
              <Typography
                variant="h6"
                align="left"
                color="text.secondary"
                component="p"
              >
                <b>Identité de l’entreprise</b>
                <br />
                Catherine Malter EI N°SIRET 91139547300018 – code APE 9609Z
                <br />2 rue de Saint Leu 94520 Périgny sur Yerres
                <br /> dispensé d'immatriculation au RCS et au RM TVA non
                applicable art 293B du CGI
                <br />
                <br />
                <b>Identité de l’hébergeur</b>
                <br />
                Ce site est hébergé par{" "}
                <Link href="https://www.ionos.fr/">IONOS France</Link>.<br></br>
                <br />
                <b>Conditions Générales de Vente</b>
                <br />
                En prenant rendez-vous, le client accepte les conditions
                générales de ventes modalités de règlement : espèces, chèque ou
                virement, en fin de séance ou à réception de la facture.
                <br />
                <br />
                <b>Politique de confidentialité</b>
                <br></br>« Dans le cadre de son activité Catherine Malter
                collecte des informations à caractère personnel sur son client
                qui répondent à la Déontologie de la Chambre syndicale de la
                Sophrologie.
                <br />
                Ces informations ne seront pas utilisées à but commercial sauf
                accord tacite du client.
                <br />
                Le client peut demander à exercer son droit d’accès en le
                demandant auprès de Catherine Malter, mettre à jour ou demander
                à supprimer ces informations.
                <br />
                Cette demande peut être formulée par mail à contact@cmalter.com
                ou par courrier au 2 rue de Saint Leu - 94520 Perigny sur Yerres
                en y adjoignant une copie de son titre d’identité ou à la CNIL 3
                place de Fontenoy- TSA 80717 – 75334 PARIS CEDEX 07»
                <br />
                <br />
                <b>Modalités d’annulation</b>
                <br />
                Toute séance non annulée 48h à l’avance sera due sauf cas de
                force majeure.
                <br />
                <br />
                <b>Traitement des réclamations et litiges :</b>
                <br />
                Catherine Malter a signé une convention avec la Chambre
                Nationale des Praticiens de la Médiation
                <br />
                27 avenue de la Libération - 42400 Saint-Chamond
                <br />
                (téléphone 09.88.30.27.72 &nbsp;
                <Link href="mailto:contact-admin@cnpm-mediation-consommation.eu">
                  contact-admin@cnpm-mediation-consommation.eu
                </Link>
                )
                <br />
                adhérente à la Chambre syndicale de la Sophrologie &nbsp;
                <Link href="https://www.chambre-syndicale-sophrologie.fr/reglement-usage-des-marques/">
                  https://www.chambre-syndicale-sophrologie.fr/reglement-usage-des-marques/
                </Link>
                <br />
                <br />
                <b>Me contacter</b>
                <br />
                par téléphone : 06.82.74.60.20
                <br />
                par mail : &nbsp;
                <Link href="mailto:contact@cmalter.com">
                  contact@cmalter.com
                </Link>
                <br />
                Cabinet sur rendez-vous : 2 rue de Saint Leu 94520 Perigny sur
                Yerres
              </Typography>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Grid>
            <Grid item xs={1} md={1}>
              &nbsp;
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
}

export default Mentions;
