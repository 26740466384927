import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography, Container, Grid, Box, Fab } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import NavigationIcon from "@mui/icons-material/Navigation";

function Quisuisje() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 2 }}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "fixed", bottom: 32, right: 16, zIndex: 1050 }}
          onClick={(e) => goToTop(e)}
        >
          <NavigationIcon />
        </Fab>
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{ pt: 8, pb: 6 }}
          >
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
              spacing={0}
              padding={0}
            >
              Qui suis-je ?
            </Typography>
          </Container>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <img
                width="100%"
                src="images/kty50.png"
                align="absbottom"
                alt="toto"
              />
            </Grid>
            <Grid item xs={1} md={1} sx={{ display: { md: "none" } }}>
              &nbsp;
            </Grid>

            <Grid item xs={10} md={8}>
              <Typography
                variant="h6"
                align="left"
                color="text.secondary"
                component="p"
              >
                <p>
                  Forte de 35 ans d’expérience dans le monde de l’entreprise à
                  travers des métiers de relations, d’écoute, d’échange et de
                  recherche d’amélioration, j’ai souhaité me tourner vers le
                  développement personnel et la sophrologie afin d’être plus en
                  phase avec mes valeurs d’accompagnement à l’amélioration du
                  bien-être, de la qualité de vie et du potentiel. Mon parcours
                  commence en 2022 par une certification comme coach
                  professionnel, coach de vie, puis une spécialisation
                  certifiante dans l’accompagnement des adolescents et des
                  jeunes adultes.
                  <br />
                  <br />
                  En 2023, afin de compléter mes techniques d’accompagnement et
                  de mieux répondre aux attentes de ma clientèle, je me suis
                  tournée vers la Sophrologie Caycédienne et une nouvelle
                  formation certifiante. Cela me permet de me dédier à
                  l’amélioration de leur bien-être, de leur qualité de vie, des
                  ressources et de leur potentiel.
                  <br />
                  <br />
                  Je suis membre de la Chambre Syndicale de la Sophrologie et de
                  l’EMCC.
                  <br />
                  <br />
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </Typography>
            </Grid>
            <Grid item xs={1} md={1} sx={{ display: { md: "none" } }}>
              &nbsp;
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
}

export default Quisuisje;
