import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography, Container, Grid, Avatar, Box, Fab } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import NavigationIcon from "@mui/icons-material/Navigation";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

import { teal } from "@mui/material/colors";

function Home() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}

      <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 2 }}>
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: "fixed", bottom: 32, right: 16, zIndex: 1050 }}
            onClick={(e) => goToTop(e)}
          >
            <NavigationIcon />
          </Fab>
          <br />
          <AnimationOnScroll
            animateIn="animate__slideInUp"
            duration="2"
            animateOnce="true"
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                xs={12}
                md={12}
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: { xs: "80px", md: "150px" },
                }}
              >
                <img
                  src="images/logoCMalterdotcom-01-VERT-real.svg"
                  alt="cmalter"
                  height="100%"
                />
              </Grid>

              <br />
              <Grid container spacing={2}>
                <Grid item xs={1} md={1}>
                  &nbsp;
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography
                    variant="h6"
                    align="center"
                    color="text.secondary"
                  >
                    <q>
                      Prends soin de ton cœur et ton cœur prendra soin de ta
                      vie.
                    </q>{" "}
                    Pr Alfonso Caycedo
                    <br />
                    &nbsp;
                  </Typography>
                </Grid>

                <Grid item xs={1} md={1}>
                  &nbsp;
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <img
                  width="100%"
                  src="images/kty01.png"
                  align="absbottom"
                  alt="toto"
                />
              </Grid>
              <Grid item xs={1} md={1} sx={{ display: { md: "none" } }}>
                &nbsp;
              </Grid>

              <Grid item xs={10} md={8}>
                <Typography variant="body" align="left" color="text.secondary">
                  <p>
                    <b>La Sophrologie, C’est quoi ?</b>
                    <br />
                    <br />
                    La Sophrologie est un ensemble de techniques très précises
                    mises au point par le neuropsychiatre Alfonso Caycedo dans
                    les années 1960, par l’alliance de techniques occidentales
                    et orientales.
                    <br />
                    Désormais beaucoup utilisée en médecine et en développement
                    personnel, la sophrologie permet d’acquérir une meilleure
                    connaissance de soi, d’affronter les défis du quotidien, de
                    développer son potentiel avec sérénité.
                    <br />
                    Elle combine des exercices qui travaillent à la fois sur la
                    respiration, la décontraction musculaire et l’imagerie
                    mentale (visualisation ou auto-conditionnement).
                    <br />
                    « L’objectif des techniques sophrologies est de renforcer
                    chez l’être humain la capacité de vivre son existence en
                    harmonie avec lui-même et avec l’univers » Pr Alfonso
                    Caycedo.
                    <br />
                    <br />
                    <b>Pour qui ?</b>
                    <br />
                    <br />
                    En individuel ou en groupe Pour les adultes et les enfants
                    (à partir de 10 ans)
                    <br />
                    La sophrologie ne se substitue pas au suivi médical, mais
                    vient l'accompagner et le renforcer.
                    <br />
                    Elle est particulièrement efficace pour améliorer la
                    confiance en soi, la gestion des émotions, du stress,
                    l’anxiété, la concentration, le sommeil, la douleur.
                    <br />
                    <br />
                    <b>Déroulement</b>
                    <br />
                    <br />
                    Aucun matériel, ni tenue vestimentaire particulière n’est
                    nécessaire La séance se pratique en position assise ou
                    debout , sans aucun contact physique
                    <br />
                    Elle se partage en 3 temps :
                    <br />
                    • Un temps d'échange, pour déterminer votre besoin et le
                    contenu de la séance
                    <br />
                    • La pratique durant laquelle je vous accompagne et vous
                    guide
                    <br />
                    • Un temps de partage sur les sensations et ressentis de la
                    séance
                    <br />
                    Elle est complétée ensuite par une pratique personnelle et
                    régulière hors séance.
                    <br />
                    <br />
                    <b>Mes partenaires</b>
                    <br />
                    <br />
                    <img
                      src="images/logo-hippocratus.svg"
                      alt="hippocratus"
                      height="10%"
                    />
                    &nbsp;&nbsp;
                    <img
                      src="images/ChbreSyndicaleSOPHRO-logo2017_HD.png"
                      alt="Chambre Syndicale de la
                      Sophrologie"
                      height="100%"
                    />
                    <br />
                  </p>
                </Typography>
              </Grid>

              <Grid item xs={1} md={1} sx={{ display: { md: "none" } }}>
                &nbsp;
              </Grid>
            </Grid>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateIn="animate__slideInUp"
            duration="2"
            animateOnce="true"
          >
            <Container
              disableGutters
              maxWidth="sm"
              component="main"
              sx={{ pt: 4, pb: 3 }}
            >
              <Typography
                component="h1"
                variant="h3"
                align="center"
                color="text.primary"
                gutterBottom
                spacing={0}
                padding={0}
              >
                TEMOIGNAGES
              </Typography>
            </Container>
            <Grid
              container
              spacing={0}
              sx={{
                display: { xs: "none", md: "flex" },
                backgroundColor: teal[100],
              }}
            >
              <Grid item md={12}>
                &nbsp;
              </Grid>

              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                md={4}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="images/T1.jpg"
                  align="middle"
                  sx={{
                    width: "auto",
                    maxWidth: "400px",
                    height: "auto",
                    maxHeight: "400px",
                  }}
                />
              </Grid>
              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                md={4}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="images/T2.jpg"
                  align="middle"
                  sx={{
                    width: "auto",
                    maxWidth: "400px",
                    height: "auto",
                    maxHeight: "400px",
                  }}
                />
              </Grid>
              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid item md={12}>
                &nbsp;
              </Grid>
              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                md={4}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  <p>
                    <q>
                      5 séances de sophrologie et mon problème de sommeil est
                      réglé. Pour une première expérience en sophro c'est une
                      réussite. Merci
                    </q>
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                md={4}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h6" valign="top" color="text.secondary">
                  <p>
                    <q>
                      J'ai trouvé en Catherine, une personne à l'écoute et
                      bienveillante. J'ai été très réceptive à la relaxation
                      durant les séances, ce qui m'a permis de diminuer le
                      stress lié à certaines situations.
                      <br />
                      Ses exercices de sophrologie m'ont permis de comprendre
                      que je pouvais agir sur mon état d'esprit. Expérience
                      positive donc!
                    </q>
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h5" align="center" color="text.secondary">
                  <p>Corinne, nov. 2023</p>
                </Typography>
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  valign="top"
                  color="text.secondary"
                >
                  <p>Isabelle, dec. 2023</p>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              sx={{
                display: { xs: "none", md: "flex" },
                backgroundColor: teal[100],
              }}
            >
              <Grid item md={12}>
                &nbsp;
              </Grid>

              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                md={4}
              >
                <Avatar
                  alt="Margaux"
                  src="images/T4.jpg"
                  align="middle"
                  sx={{
                    width: "auto",
                    maxWidth: "400px",
                    height: "auto",
                    maxHeight: "400px",
                  }}
                />
              </Grid>
              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                md={4}
              ></Grid>
              <Grid item md={1}>
                &nbsp;
              </Grid>
              <Grid item md={12}>
                &nbsp;
              </Grid>
              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                md={4}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  <p>
                    <q>
                      Excellente sophrologue ! Très à l’écoute, s’adapte très
                      bien en fonction des besoins de la personne ! Pour les
                      sportifs en préparation de championnats c’est top ! Je
                      recommande
                    </q>
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                md={4}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h6" valign="top" color="text.secondary">
                  <p>&nbsp;</p>
                </Typography>
              </Grid>
              <Grid
                item
                md={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h5" align="center" color="text.secondary">
                  <p>Margaux, jan. 2024</p>
                </Typography>
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  valign="top"
                  color="text.secondary"
                >
                  <p></p>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                xs={10}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="images/T1.jpg"
                  align="middle"
                  sx={{
                    width: "auto",
                    maxWidth: "300px",
                    height: "auto",
                    maxHeight: "300px",
                    backgroundColor: teal[100],
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  <p>
                    <q>
                      5 séances de sophrologie et mon problème de sommeil est
                      réglé. Pour une première expérience en sophro c'est une
                      réussite. Merci
                    </q>
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h5" align="center" color="text.secondary">
                  <p>Corinne, nov. 2023</p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                xs={10}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="images/T2.jpg"
                  align="middle"
                  sx={{
                    width: "auto",
                    maxWidth: "300px",
                    height: "auto",
                    maxHeight: "300px",
                    backgroundColor: teal[100],
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  <p>
                    <q>
                      J'ai trouvé en Catherine, une personne à l'écoute et
                      bienveillante. J'ai été très réceptive à la relaxation
                      durant les séances, ce qui m'a permis de diminuer le
                      stress lié à certaines situations. Ses exercices de
                      sophrologie m'ont permis de comprendre que je pouvais agir
                      sur mon état d'esprit. Expérience positive donc!
                    </q>
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h5" align="center" color="text.secondary">
                  <p>Isabelle, dec. 2023</p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                xs={10}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src="images/T4.jpg"
                  align="middle"
                  sx={{
                    width: "auto",
                    maxWidth: "300px",
                    height: "auto",
                    maxHeight: "300px",
                    backgroundColor: teal[100],
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[100],
                }}
              >
                &nbsp;
              </Grid>

              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  <p>
                    <q>
                      Excellente sophrologue ! Très à l’écoute, s’adapte très
                      bien en fonction des besoins de la personne ! Pour les
                      sportifs en préparation de championnats c’est top ! Je
                      recommande.
                    </q>
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                &nbsp;
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: teal[300],
                }}
              >
                <Typography variant="h5" align="center" color="text.secondary">
                  <p>Margaux, jan. 2024</p>
                </Typography>
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Box>
        <br />
        <br />
      </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
}

export default Home;
